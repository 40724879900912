import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';
import * as  CiteProc from 'citeproc';
import { readFileSync } from 'fs';
import { HttpClient } from '@angular/common/http';
import {RouteService} from "../../../../../../../app/core/services/route.service";
import {Router} from "@angular/router";

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'sub')
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

  /**
   * SUB Hamburg / eWW
   * CC badge object to display info on publication page
   */
  public ccBadge = {
    'url' : '',
    'img' : '',
  };

  private citationStyles: { [key: string]: string } = {
    "DIN_1505-2": "din-1505-2",
    "APA": "apa",
    "BibTeX": "bibtex",
    "Chicago": "chicago-author-date-de",
  };

  public citations = [];

  constructor(protected routeService: RouteService,
              protected router: Router,
              private httpClient: HttpClient) {
    super(routeService, router);
  }

  /**
   * SUB Hamburg / eWW
   * Added call to ngOnInit() to initialize CC badges
   */
  ngOnInit() {
    super.ngOnInit();
    this.initCcBadges();
    this.initCitations();
  }

  /**
   * SUB Hamburg / eWW
   * Initialize CC badges and links
   */
  initCcBadges () {
    const badges = {
      'https://creativecommons.org/publicdomain/zero/1.0/' : ['cc-zero.svg'],
      'https://creativecommons.org/licenses/by/4.0/' : ['by.svg'],
      'https://creativecommons.org/licenses/by-sa/4.0/' : ['by-sa.svg'],
      'https://creativecommons.org/licenses/by-nd/4.0/' : ['by-nd.svg'],
      'https://creativecommons.org/licenses/by-nc/4.0/' : ['by-nc.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-sa/4.0/' : ['by-nc-sa.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-nd/4.0/' : ['by-nc-nd.eu.svg'],
    };
    for (const metadataField of Object.keys(this.object.metadata)) {
      if (metadataField == 'dc.rights') {
        this.ccBadge = {
          'url' : this.object.metadata[metadataField][0]['value'],
          'img' : badges[this.object.metadata[metadataField][0]['value']],
        };
      }
    }
  }

  /**
   * SUB Hamburg / eWW
   * Initialize citations
   */
  initCitations () {

    const citationItems = {
      "item": {
        id: "item",
        /* type: "book",
        author: [
          { family: "Doe", given: "John" },
          { family: "Smith", given: "Jane" },
          { family: "Brown", given: "James" },
          { family: "White", given: "Sally" }
        ],
        title: "Sample Book",
        issued: { "date-parts": [[2020]] }, */
      },
    };

    for (const metadataField of Object.keys(this.object.metadata)) {
      if (metadataField == 'dc.type') {
        citationItems['item']['type'] = this.object.metadata[metadataField][0]['value'];
      }

      if (metadataField == 'dc.title') {
        citationItems['item']['title'] = this.object.metadata[metadataField][0]['value'];
      }

      if (metadataField == 'dc.contributor.author') {
        var valueArr = this.object.metadata[metadataField][0]['value'].split(',');
        if (!citationItems['item']['author']) {
          citationItems['item']['author'] = [];
        }
        citationItems['item']['author'].push({'family': valueArr[0], 'given': valueArr[1]});
      }

      if (metadataField == 'dc.contributor.editor') {
        var valueArr = this.object.metadata[metadataField][0]['value'].split(',');
        if (!citationItems['item']['editor']) {
          citationItems['item']['editor'] = [];
        }
        citationItems['item']['editor'].push({'family': valueArr[0], 'given': valueArr[1]});
      }

      if (metadataField == 'dc.contributor.other') {
        var valueArr = this.object.metadata[metadataField][0]['value'].split(',');
        if (!citationItems['item']['other']) {
          citationItems['item']['other'] = [];
        }
        citationItems['item']['other'].push({'family': valueArr[0], 'given': valueArr[1]});
      }

      if (metadataField == 'dc.date.issued') {
        citationItems['item']['issued'] = this.object.metadata[metadataField][0]['value'];
      }
    }


    for (var citationStyleKey in this.citationStyles) {
      console.log(this.citationStyles[citationStyleKey]);

      const citeprocEngine = this.getProcessor(this.citationStyles[citationStyleKey], citationItems);

      citeprocEngine.updateItems(["item"]);

      const bibliography = citeprocEngine.makeBibliography();
      console.log("Bibliography CSS:", bibliography[0]);
      console.log("Bibliography Entries:", bibliography[1]);

      this.citations.push({ title: citationStyleKey, details: bibliography[1][0], showDetails: false });
    }
  }

  toggleDetails(index: number) {
    this.citations[index].showDetails = !this.citations[index].showDetails;
  }

  getProcessor(styleID, citationItems) {
    const citeprocSys = {
      retrieveLocale: function (lang){
        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://raw.githubusercontent.com/citation-style-language/locales/master/locales-' + lang + '.xml', false);
        xhr.send(null);
        return xhr.responseText;
      },
      retrieveItem: function(id){
        return citationItems[id];
      }
    };

    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://raw.githubusercontent.com/citation-style-language/styles/master/' + styleID + '.csl', false);
    xhr.send(null);
    var styleAsText = xhr.responseText;
    var citeproc = new CiteProc.Engine(citeprocSys, styleAsText);
    return citeproc;
  };
}
